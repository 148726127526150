@import url('https://fonts.googleapis.com/css2?family=Golos+Text:wght@400;600&display=swap');

$hd-width: 1600;

$screen-width: 100vw;
$screen-height: 100vh;

$container-width: 100%;
$container-height: 640/900 * 100%;

$banner-width: 100%;
$banner-height: 260/900 * 100%;

$text-color: #FFFFFF;
$text-color-alpha-85: #FFFFFFD9;
$text-font-family: 'Golos Text', sans-serif;
$screen-padding-x: 67rem;
$screen-padding-y: 52rem;
$screen-background-color: #004466;
$font-weight-400: 400;
$font-weight-600: 600;

$font-size-main: 64rem;
$line-height-main: 64rem;
$gap-main: 64rem;

$font-size-sub-main: 48rem;
$font-size-title-municipality: 32rem;

$font-size-clock: 52rem;
$font-size-clock-month-year: 20rem;

$font-size-block: 38rem;

$clock-date-column-gap: 4rem;
$clock-date-month-year-font-size: 20rem;
$clock-line-height: 54rem;
$clock-gap: 32rem;

$header-title-height: 54rem;
$header-title-line-height: 48rem;
$header-title-color: #FFFFFFD9;
$header-title-weight: 400;
$header-title-text-font-size: 48rem;
$header-title-text-line-height: 48rem;
$header-title-text-weight: 400;
$header-title-text-color: #FFFFFFD9;

$header-place-height: 123rem;
$header-place-name-font-size: 64rem;
$header-place-name-weight: 600;
$header-place-name-line-height: 76.8rem;
$header-place-municipality-font-size: 32rem;
$header-place-municipality-weight: 400;
$header-place-municipality-line-height: 38.4rem;
$header-place-municipality-color: #99D5FF;

$info-height: 140rem;
$info-column-gap: 82rem;

$info-plots-height: 62rem;
$info-plots-count-min-width: 380rem;
$info-plots-count-height: 126rem;
$info-plots-count-font-size: 64rem;
$info-plots-count-weight: 600;
$info-plots-count-line-height: 68rem;
$info-plots-count-addition-text-font-size: 48rem;
$info-plots-count-addition-text-weight: 400;
$info-plots-count-addition-text-line-height: 57.6rem;
$info-plots-count-addition-text-color: #FFFFFFD9;

$info-prices-font-size: 64rem;
$info-prices-weight: 400;
$info-prices-line-height: 70rem;

$plots-column-gap: 42rem;
$plots-block-border-radius: 8rem;
$plots-block-padding: 8rem 24rem 8rem 24rem;
$plots-block-gap: 12rem;
$plots-block-border: solid 2px $text-color;
$plots-block-font-size: 38rem;
$plots-block-weight: 400;
$plots-block-line-height: 45.6rem;
$plots-block-num-font-size: 38rem;
$plots-block-num-weight: 600;
$plots-block-num-line-height: 45.6rem;

html {
  font-size: calc(100 * 100vw / $hd-width / 100);
}

body {
  background-color: rgba(0, 68, 102, 0.7);
}

.screen {
  width: $screen-width;
  height: $screen-height;

  display: flex;
  flex-direction: column;
  background-color: $screen-background-color;
  font-family: $text-font-family;

  .container {
    width: $container-width;
    height: $container-height;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: $screen-padding-x $screen-padding-y;
    color: $text-color;
    box-sizing: border-box;
    text-align: left;

    .header {
      .title {
        height: $header-title-height;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        color: $header-title-color;
        font-weight: $header-title-weight;

        font-size: $font-size-sub-main;
        line-height: $header-title-line-height;

        .title-text {
          color: $header-title-text-color;
          font-weight: $header-title-text-weight;
          font-size: $header-title-text-font-size;
          line-height: $header-title-text-line-height;
        }

        .clock {
          color: $text-color;
          font-weight: $font-weight-400;
          font-size: $font-size-clock;
          line-height: $clock-line-height;

          display: flex;
          justify-content: space-between;
          gap: $clock-gap;

          .date {
            display: grid;
            grid-template-areas:
                      "day month"
                      "day year";

            column-gap: $clock-date-column-gap;

            .day {
              grid-area: day;
            }

            .month {
              grid-area: month;
              font-size: $clock-date-month-year-font-size;
              line-height: $clock-line-height / 2;
            }

            .year {
              grid-area: year;
              font-size: $clock-date-month-year-font-size;
              line-height: $clock-line-height / 2;
            }
          }
        }
      }

      .place {
        height: $header-place-height;
        display: flex;
        flex-direction: column;
        row-gap: 8rem;

        .name {
          font-size: $header-place-name-font-size;
          font-weight: $header-place-name-weight;
          line-height: $header-place-name-line-height;
          text-align: left;

          overflow: hidden; // Обрезка содержимого, которое не помещается
          white-space: nowrap; // Текст в одну строку без переноса
          text-overflow: ellipsis; // Добавление многоточия в конце обрезанного текста
        }

        .municipality {
          font-size: $header-place-municipality-font-size;
          font-weight: $header-place-municipality-weight;
          line-height: $header-place-municipality-line-height;
          color: $header-place-municipality-color;

          text-align: left;
          display: flex;
          align-item: center;
          column-gap: 4rem;

          img {
            width: 36rem;
            height: 36rem;
          }

        }
      }
    }

    .info {
      height: $info-height;

      display: flex;
      flex-direction: row;
      column-gap: $info-column-gap;

      .plots-count {
        min-width: $info-plots-count-min-width;
        height: $info-plots-count-height;
        font-size: $info-plots-count-font-size;
        font-weight: $info-plots-count-weight;
        line-height: $info-plots-count-line-height;
        text-align: left;

        .addition-text {
          color: $info-plots-count-addition-text-color;
          font-size: $info-plots-count-addition-text-font-size;
          font-weight: $info-plots-count-addition-text-weight;
          line-height: $info-plots-count-addition-text-line-height;
          text-align: left;
        }
      }

      .prices {
        font-size: $info-prices-font-size;
        font-weight: $info-prices-weight;
        line-height: $info-prices-line-height;
        text-align: left;
      }
    }

    .plots {
      height: $info-plots-height;
      display: flex;
      flex-direction: row;
      column-gap: $plots-column-gap;

      .block {
        width: 712rem;
        height: 62rem;

        display: flex;

        gap: $plots-block-gap;
        border-radius: $plots-block-border-radius;
        border: $plots-block-border;
        box-sizing: border-box;

        .main-text {
          flex: 1;
          text-align: center;
          margin: $plots-block-padding;

          font-size: $plots-block-font-size;
          font-weight: $plots-block-weight;
          line-height: $plots-block-line-height;

          .num {
            font-size: $plots-block-num-font-size;
            font-weight: $plots-block-num-weight;
            line-height: $plots-block-num-line-height;
          }
        }
      }
    }
  }

  .banner {
    width: $banner-width;
    height: $banner-height;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      //width: 100%;
      //aspect-ratio: 1600/260;
      //
      //position: fixed;
      //bottom: 0;
      //left: 0;
      //object-fit: cover; /* Изображение будет покрывать весь блок */
    }

    .any {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-end;
      padding: 10rem;
      box-sizing: border-box;

      img {
        width: auto;
        height: 30%;
        object-fit: scale-down;
      }

      .bikLogo {
        margin-right: 50rem;
      }
    }
  }
}
